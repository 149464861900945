<template>
  <div class="page-container bank-account-page">
    <portal to="page-name">Cadastro de conta bancária</portal>
    <div class="card card-page">
      <div class="card-header">
        <div class="form-group float-right status-toggle">
          <label class="form-switch">
            <input type="checkbox" v-model="form.active">
            <i class="form-icon"></i> Registro ativo
          </label>
        </div>
        <h1 class="card-title">Cadastro de conta bancária</h1>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-6 col-md-8 col-sm-12 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="name" class="form-label">Nome</label>
            <input type="text" id="name" name="name"
                   v-model="form.name" class="form-input"
                   @blur="$v.form.name.$touch()">
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-2 col-md-4 col-sm-12 form-group">
            <label class="form-label">Caixa interno</label>
            <select class="form-select"
                    v-model="form.cashier">
              <option :value="false">Não</option>
              <option :value="true">Sim</option>
            </select>
          </div>
        </div>
        <div class="columns" v-if="!form.cashier">
          <div class="column col-6 col-md-12 col-sm-12 form-group">
            <label class="form-label">Banco</label>
            <select class="form-select"
                    v-model="form.bankId">
              <option value="">[Selecione um banco]</option>
              <option v-for="(item, i) in banks"
                      :value="item.id" :key="i">
                {{ item.code }} - {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-2 col-md-4 col-sm-12 form-group">
            <label for="agency" class="form-label">Agência</label>
            <input type="text" id="agency" name="agency" v-model="form.agency"
                   class="form-input text-center"
                   placeholder="00000" v-mask="'00000'">
          </div>
          <div class="column col-2 col-md-4 col-sm-12 form-group">
            <label for="number" class="form-label">Conta</label>
            <input type="text" id="number" name="number" v-model="form.number"
                   placeholder="0000000" class="form-input text-center"
                   v-mask="'0000000000'" maxlength="8">
          </div>
          <div class="column col-2 col-md-4 col-sm-12 form-group">
            <label for="digit" class="form-label">DV</label>
            <input type="text" id="digit" name="digit" v-model="form.digit"
                   placeholder="00" class="form-input text-center" v-mask="'00'">
          </div>
          <div class="column col-12">
            <label class="form-switch d-inline-block">
              <input type="checkbox"
                     @change="clean"
                     v-model="changeBalance">
              <i class="form-icon"></i>Ajustar saldo
            </label>
          </div>
          <div class="column col-3 col-md-4 col-sm-12 form-group"
               :class="{'has-error': $v.form.balanceDate.$error}"
               v-if="changeBalance">
            <label class="form-label">Data</label>
            <dx-input-date
              class="form-input"
              v-model="form.balanceDate"
              @blur="$v.form.balanceDate.$touch()"
            />
            <template v-if="$v.form.balanceDate.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.balanceDate.required">Campo obrigatório</div>
              <div class="form-input-hint"
                   v-if="!$v.form.balanceDate.date">Data inválida</div>
            </template>
          </div>
          <div class="column col-3 col-md-4 col-sm-12 form-group"
               :class="{'has-error': $v.form.balanceValue.$error}"
               v-if="changeBalance">
            <label class="form-label">Saldo</label>
            <dx-input-number class="form-input"
                             v-model="form.balanceValue"
                             @blur="$v.form.balanceValue.$touch()"
                             :precision="2"/>
            <template v-if="$v.form.balanceValue.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.balanceValue.required">Campo obrigatório</div>
            </template>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1" @click="save()"
                :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from 'src/mixins/form';
import { mergeFrom } from '@/helpers/object';
import moment from 'moment';
import { date } from '../../../data/validators';

export default {
  mixins: [formMixin],
  data() {
    return {
      isNew: true,
      loading: false,
      saving: false,
      changeBalance: false,
      banks: [],
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    this.loadBanks();
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  validations() {
    const rules = {
      form: {
        name: { required },
        active: { required },
      },
    };

    if (this.changeBalance) {
      rules.form.balanceValue = { required };
      rules.form.balanceDate = { required, date };
    }

    return rules;
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`/bank-accounts/${this.form.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      const params = {
        name: this.form.name,
      };

      const { data: found } = await this.$http.get('/bank-accounts', { params });

      if (found.items.length > 0 && found.items[0].id !== this.form.id) {
        this.$toast.show('Conta já adicionada', { type: 'error' });
        return;
      }

      this.saving = true;

      const account = this.clone(this.form);
      if (account.cashier) {
        delete account.bankId;
      }

      if (!this.changeBalance) {
        delete account.balanceDate;
        delete account.balanceValue;
      }

      const request = this.isNew
        ? this.$http.post('/bank-accounts', account)
        : this.$http.put(`/bank-accounts/${this.form.id}`, account);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          if (this.isNew) {
            this.$router.replace(`/financial/bank-accounts/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .finally(() => {
          this.changeBalance = false;
          this.saving = false;
        });
    },
    loadBanks() {
      const params = {
        limit: 0,
      };

      return this.$http.get('/services/banks', { params })
        .then(({ data }) => {
          this.banks = data.items;
        })
        .catch(() => {});
    },
    clean() {
      this.form.balanceValue = '';
    },
    blankForm() {
      return {
        id: '',
        name: '',
        bankId: '',
        agency: '',
        number: '',
        digit: '',
        balanceValue: '',
        balanceDate: moment().format('YYYY-MM-DD'),
        cashier: false,
        active: true,
      };
    },
  },
};
</script>

<style lang="scss">
  .bank-account-page {
  }
</style>
